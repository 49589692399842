<template>
  <PageHeader />
  <h1>Supporting Engineers</h1>
  <div class="current-time-container">
    <div class="filter-container">
      <input type="text" v-model="searchQuery" placeholder="Search by Name" />
      <input type="text" id="projectFilter" v-model="uinSearchQuery" placeholder="Search by UIN" />
      <input type="text" v-model="roleSearchQuery" placeholder="Search by Role" />
      <input type="text" id="dobFilter" v-model="dobSearchQuery" placeholder="Search by Date of Birth" />
      <button class="clear-filter-button" @click="clearFilters">Clear Filters</button>
    </div>
  </div>
  <div class="pagination-button-container">
    <div class="pagination-container">
      <Pagination :totalPages="totalPages" :perPage="perPage" :currentPage="currentPage" @page-change="onPageChange" />
    </div>
    <div class="pagination-info">
      Showing {{ startIndex + 1 }}-{{ endIndex }} of {{ filteredUsersLength }} users
    </div>
  </div>
  <edit-user-modal v-if="showEditUser" @close="closeEditUser"></edit-user-modal>
  <div v-if="showQRCode" class="qr-code-modal">
    <div class="qr-code-content">
      <h3>QR Code for {{ selectedUser.first_name }} {{ selectedUser.last_name }}</h3>
      <qr-code :text="qrText" ref="qrCodeCanvas" size="200"
        style="margin-left: 130px; margin-top: 20px; margin-bottom: 20px;"></qr-code>
      <div class="button-container">
        <button class="ins-close-button" @click="closeQRCodeModal">Close</button>
        <button class="ins-submit-button" @click="generateAndDownloadQRCode(selectedUser)">Save & Download</button>
      </div>
    </div>
  </div>

  <div class="container-fluid-trainee">
    <table class="data-table-trainee">
      <thead>
        <tr>
          <th class="id-column" @click="sortBy('user_id')">
            ID
            <span v-if="sortField === 'user_id'" v-html="getFieldArrow()"></span>
          </th>
          <th class="name-column" @click="sortBy('first_name')">
            First Name
            <span v-if="sortField === 'first_name'" v-html="getFieldArrow('first_name')"></span>
          </th>
          <th class="name-column">Last Name</th>
          <th style="width: 20%;">Email</th>
          <th class="skinny-column">UIN</th>
          <th class="date-column">Date of Birth</th>
          <th class="skinny-column">Actions</th>
          <th class="skinny-column">Generate QR</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="item in filteredUsers" :key="item.user_id">
          <td>{{ item.user_id }}</td>
          <td>{{ item.first_name }}</td>
          <td>{{ item.last_name }}</td>
          <td>{{ item.email }} </td>
          <td>{{ item.UIN }} </td>
          <td>{{ item.date_of_birth }} </td>
          <td class="center-icons">
            <i class="fas fa-edit edit-icon" @click="viewEditUser(item.user_id)"></i>
            <i class="fas fa-trash-alt delete-icon" @click="deleteUser(item.user_id)"></i>
          </td>
          <td class="center-icons">
            <i class="fas fa-qrcode qr-code-icon" @click="viewQRCode(item)"></i>
          </td>
        </tr>
        <tr v-if="filteredUsers.length === 0">
          <td colspan="100" style="text-align: center;">No Matching Supporting Engineers</td>
        </tr>
      </tbody>
    </table>
  </div>
  <div>
    <button class="btn btn-primary rounded" id=addlogbookButton @click="goToAddUser">Add User</button>
  </div>
</template>

<script>
import PageHeader from './InstructorHeader.vue';
import axios from 'axios';
import Pagination from '@/components/Examiner/TablePagination.vue';
import EditUserModal from '@/components/Examiner/EditUserModal.vue';
import VueQRCodeComponent from 'vue-qrcode-component';
import jsPDF from 'jspdf';

export default {
  name: 'GenerateQR',
  components: {
    Pagination,
    PageHeader,
    EditUserModal,
    'qr-code': VueQRCodeComponent,
  },
  data() {
    return {
      users: [],
      sortField: 'user_id',
      sortDirection: 'asc',
      searchQuery: '',
      uinSearchQuery: '',
      roleSearchQuery: '',
      dobSearchQuery: '',
      pobirthSearchQuery: '',
      currentPage: 1,
      perPage: 10,
      totalPages: 0,
      showEditUser: false,
      filteredUsersLength: 0,
      showQRCode: false, // State for showing QR code modal
      selectedUser: null, // Selected user for QR code
      qrText: '',
    };
  },
  watch: {
    searchQuery(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.currentPage = 1; // Reset currentPage to 1 when searchQuery changes
        this.calculateTotalPages();
      }
    },
    uinSearchQuery(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.currentPage = 1; // Reset currentPage to 1 when searchQuery changes
        this.calculateTotalPages();
      }
    },
    roleSearchQuery(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.currentPage = 1; // Reset currentPage to 1 when searchQuery changes
        this.calculateTotalPages();
      }
    },
    dobSearchQuery(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.currentPage = 1; // Reset currentPage to 1 when searchQuery changes
        this.calculateTotalPages();
      }
    },
    pobirthSearchQuery(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.currentPage = 1; // Reset currentPage to 1 when searchQuery changes
        this.calculateTotalPages();
      }
    },
  },
  async mounted() {
    try {
      const token = localStorage.getItem('token');
      const headers = { Authorization: `Bearer ${token}` };
      // Fetch all Users
      let result = await axios.get('/api/users', { headers });
      this.users = result.data.filter(user => user.role === 'support_engineer');
      console.log(this.users);
      this.totalPages = Math.ceil(this.users.length / this.perPage);
    } catch (error) {
      console.error('An error occurred while fetching data:', error);
    }
  },
  methods: {
    deleteUser(user_id) {
      if (confirm("Are you sure you want to delete this user?")) {
        if (confirm("This action will PERMANENTLY delete the user from the database. Do you wish to continue?")) {
          // If the user confirms the deletion, send a request to delete the user.
          const token = localStorage.getItem('token');
          const headers = { Authorization: `Bearer ${token}` };
          axios
            .delete(`/api/users/${user_id}`, { headers })
            .then(response => {
              if (response.status === 200) {
                // Remove the deleted user from the local data
                this.users = this.users.filter(user => user.user_id !== user_id);
                window.alert('User deleted Successfully');
              }
            })
            .catch(error => {
              console.error('An error occurred while deleting the user:', error);
            });
        }
      }
    },
    viewQRCode(user) {
      this.selectedUser = user; // Set the selected user
      this.qrText = `User ID: ${user.user_id}, Name: ${user.first_name} ${user.last_name}, Email: ${user.email}`;
      this.showQRCode = true; // Show the QR code modal
    },
    closeQRCodeModal() {
      this.showQRCode = false; // Close the QR code modal
      this.selectedUser = null; // Clear the selected user
    },
    generateAndDownloadQRCode(user) {
      this.selectedUser = user; // Set the selected user

      // Generate the QR code text with necessary user information
      this.qrText = `User ID: ${user.user_id}, Name: ${user.first_name} ${user.last_name}, Email: ${user.email}`;

      this.$nextTick(() => {
        const qrCanvas = this.$refs.qrCodeCanvas.$el.querySelector('canvas');
        if (qrCanvas) {
          const options = {
            year: 'numeric', month: 'long', day: 'numeric',
            hour: '2-digit', minute: '2-digit', second: '2-digit'
          };
          const formattedDateTime = new Date().toLocaleDateString('en-US', options);

          // Create the QR code image data from the canvas
          const imgData = qrCanvas.toDataURL('image/png');
          console.log(imgData);

          // Data to send to the backend
          const qrCodeData = {
            user_id: user.user_id,
            qr_code: imgData,  // Base64 image data of the QR code
            qr_code_data: this.qrText,
            is_active: true,   // Set as active (adjust based on your business logic)
            generated_at: new Date().toISOString(),  // Timestamp of when the QR code is generated
          };

          // Send a POST request to the backend to store the QR code
          const token = localStorage.getItem('token'); // Assuming you have a token for authentication
          const headers = { Authorization: `Bearer ${token}` }; // Add the token to headers

          axios.post('/api/qrcodes', qrCodeData, { headers })
            .then(response => {
              if (response.status === 201) {
                // QR code was successfully stored in the backend
                console.log('QR Code successfully created and stored:', response.data);

                // Now create and download the PDF with the QR code
                const pdf = new jsPDF({
                  orientation: 'portrait',
                  unit: 'mm',
                  format: 'a4'
                });

                // Add the text and QR code to the PDF
                const qrWidth = qrCanvas.width * (210 / (qrCanvas.width / (25.4 / 96)));
                const qrHeight = qrCanvas.height * (210 / (qrCanvas.width / (25.4 / 96)));
                const x = (pdf.internal.pageSize.getWidth() - qrWidth) / 2;
                const y = (pdf.internal.pageSize.getHeight() - qrHeight) / 2;

                pdf.text(`QR code for SE: ${user.first_name} ${user.last_name} \n Date - ${formattedDateTime}`, 105, y - 10, { align: 'center' });
                pdf.addImage(imgData, 'PNG', x, y, qrWidth, qrHeight);

                const now = new Date();
                const day = String(now.getDate()).padStart(2, '0');
                const month = String(now.getMonth() + 1).padStart(2, '0'); // Months are zero-based
                const year = now.getFullYear();
                const hours = String(now.getHours()).padStart(2, '0');
                const minutes = String(now.getMinutes()).padStart(2, '0');
                const seconds = String(now.getSeconds()).padStart(2, '0');
                const dateTime = `${day}_${month}_${year}_${hours}_${minutes}_${seconds}`;

                // Save the PDF
                pdf.save(`SE-QRCode-${user.user_id}-${dateTime}.pdf`);
              }
            })
            .catch(error => {
              console.error('Error occurred while storing QR Code:', error);
              alert('An error occurred while generating and storing the QR code.');
            });
        }
      });
    },
    sortBy(field) {
      if (this.sortField === field) {
        this.sortDirection = this.sortDirection === 'asc' ? 'desc' : 'asc';
      } else {
        this.sortField = field;
        this.sortDirection = 'asc';
      }
      this.users.sort((a, b) => {
        const valueA = a[field];
        const valueB = b[field];
        if (typeof valueA === 'number' && typeof valueB === 'number') {
          return (this.sortDirection === 'asc' ? 1 : -1) * (valueA - valueB);
        } else if (typeof valueA === 'string' && typeof valueB === 'string') {
          return (this.sortDirection === 'asc' ? 1 : -1) * valueA.localeCompare(valueB);
        } else {
          return 0;
        }
      });
    },
    getFieldArrow(field = 'user_id') {
      if (this.sortField === field) {
        return this.sortDirection === 'asc' ? '▲' : '▼';
      } else {
        return '';
      }
    },
    onPageChange(page) {
      this.currentPage = page;
      this.calculateTotalPages();
    },
    goToAddUser() {
      this.$router.push({ name: 'AddUser' });
    },
    onUserSearch() {
      this.currentPage = 1;
    },
    onUINSearch() {
      this.currentPage = 1;
    },
    onRoleSearch() {
      this.currentPage = 1;
    },
    ondobSearch() {
      this.currentPage = 1;
    },
    onpobirthSearch() {
      this.currentPage = 1;
    },
    viewEditUser(user_id) {
      localStorage.setItem('user_id', user_id);
      this.showEditUser = true; // Show the modal when the button is clicked
    },
    closeEditUser() {
      this.showEditUser = false; // Close the modal
    },
    calculateTotalPages() {
      this.computeFilteredUsers();
      this.totalPages = Math.ceil(this.filteredUsersLength / this.perPage);
    },
    computeFilteredUsers() {
      const startIndex = (this.currentPage - 1) * this.perPage;
      const endIndex = startIndex + this.perPage;
      const filteredUsers = this.users.filter((user) => {
        const fullName = (user.first_name + ' ' + user.last_name || "").toLowerCase(); // Fallback to empty string if null or undefined
        const userMatch = fullName.includes(this.searchQuery.toLowerCase()) || this.searchQuery === '';
        const UIN = (user.UIN || "").toString(); // Fallback to empty string if null or undefined
        const uinMatch = UIN.includes(this.uinSearchQuery.toLowerCase()) || this.uinSearchQuery === '';
        const role = (user.role || "").toString(); // Fallback to empty string if null or undefined
        const roleMatch = role.includes(this.roleSearchQuery.toLowerCase()) || this.roleSearchQuery === '';
        const date_of_birth = (user.date_of_birth || "").toString(); // Fallback to empty string if null or undefined
        const dobMatch = date_of_birth.includes(this.dobSearchQuery.toLowerCase()) || this.dobSearchQuery === '';
        const place_of_birth = (user.place_of_birth || "").toString(); // Fallback to empty string if null or undefined
        const pobirthMatch = place_of_birth.toLowerCase().startsWith(this.pobirthSearchQuery.toLowerCase()) || place_of_birth.includes(this.pobirthSearchQuery.toLowerCase()) || this.pobirthSearchQuery === '';

        return userMatch && uinMatch && roleMatch && dobMatch && pobirthMatch;
      });
      console.log(filteredUsers);
      this.filteredUsersLength = filteredUsers.length;
      console.log(this.filteredUsersLength);
      return filteredUsers.slice(startIndex, endIndex);
    },
    clearFilters() {
      // Clear all filter-related data properties
      this.searchQuery = '',
        this.uinSearchQuery = '',
        this.roleSearchQuery = '',
        this.dobSearchQuery = '',
        this.pobirthSearchQuery = '',
        // Recalculate the total pages and update the data
        this.calculateTotalPages();
    },
  },
  computed: {
    startIndex() {
      return (this.currentPage - 1) * this.perPage;
    },
    endIndex() {
      const end = this.startIndex + this.perPage;
      return end < this.filteredUsersLength ? end : this.filteredUsersLength;
    },
    filteredUsers() {
      return this.computeFilteredUsers();
    },
  },
};
</script>


<style scoped>
span:hover {
  cursor: pointer;
  color: blue;
}

.container-fluid-trainee {
  padding-top: 10px;
  margin-left: 1%;
  margin-right: 1%;
}

button.btn#addlogbookButton {
  padding: 10px 10px;
  font-size: 16px;
  width: 180px;
  height: 40px;
  margin-top: 0;
  margin-bottom: 15px;
  margin-right: 20px;
  border: 1px solid rgb(0, 96, 134);
  background: #2952c4;
  color: white;
  cursor: pointer;
  text-align: center;
  transition: transform 0.2s, box-shadow 0.2s;
  float: right;
}

button.btn#addlogbookButton:hover {
  background-color: #0c1a61;
  transition: transform 0.2s, box-shadow 0.2s;
}

button.btn#assignTraineeButton {
  padding: 10px 10px;
  font-size: 16px;
  width: 200px;
  height: 40px;
  margin-top: 0;
  margin-bottom: 15px;
  margin-right: 20px;
  border: 1px solid rgb(0, 96, 134);
  background: #2952c4;
  color: white;
  cursor: pointer;
  text-align: center;
  transition: transform 0.2s, box-shadow 0.2s;
  float: right;
}

button.btn#assignTraineeButton:hover {
  background-color: #0c1a61;
  transition: transform 0.2s, box-shadow 0.2s;
}

button.btn#importTraineeButton {
  padding: 10px 10px;
  font-size: 16px;
  width: 180px;
  height: 40px;
  margin-top: 0;
  margin-bottom: 15px;
  margin-right: 20px;
  border: 1px solid rgb(17, 80, 20);
  background: #2c752c;
  color: white;
  cursor: pointer;
  text-align: center;
  transition: transform 0.2s, box-shadow 0.2s;
  float: right;
}

button.btn#importTraineeButton:hover {
  background-color: #184218;
  transition: transform 0.2s, box-shadow 0.2s;
}

input[type="text"] {
  width: 100%;
  height: 35px;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  margin-top: 5px;
  margin-bottom: 1px;
  margin-right: 20px;
}

input[type="text"]#productFilter {
  padding: 5px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 16px;
  width: 200px;
  position: absolute;
  right: 210px;
  top: 0px;
}

input[type="text"]#projectFilter {
  padding: 5px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 16px;
  width: 200px;
  position: absolute;
  right: 210px;
  top: 0px;
}

input[type="text"]#dobFilter {
  padding: 5px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 16px;
  width: 200px;
  position: absolute;
  right: 210px;
  top: 41px;
}

input[type="text"]#pobirthFilter {
  padding: 5px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 16px;
  width: 200px;
  position: absolute;
  right: 210px;
  top: 82px;
}

h1 {
  padding-top: 30px;
  padding-left: 3%;
  text-align: left;
}

.filter-container {
  margin: 20px 0;
  position: absolute;
  width: 200px;
  top: 0px;
  right: 20px;
}

label {
  margin-top: 8px;
}

.current-time-container {
  position: absolute;
  top: 80px;
  right: 10px;
}

.data-table-trainee {
  width: 100%;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  border-collapse: collapse;
  margin-left: 0;
  align-items: left;
  margin-bottom: 30px;
}

.data-table-trainee th,
.data-table-trainee td {
  text-align: left;
  /* Align content to the left */
  padding-left: 10px;
}

.data-table-trainee td.center-icons {
  text-align: center;
}

.pagination-container {
  margin-top: 40px;
  margin-bottom: 0;
  display: flex;
  margin-left: 3%;
}

.edit-icon {
  font-size: 16px;
  /* Adjust the size as needed */
  color: #615f5f;
  /* Change the color to your preference */
  cursor: pointer;
  transition: color 0.2s, transform 0.2s, box-shadow 0.2s;
}

.edit-icon:hover {
  color: #2b2a2a;
  /* Change the color to your preference */
  cursor: pointer;
  transform: scale(1.1);
  /* Example transform for scaling on hover */
}

.delete-icon {
  font-size: 16px;
  color: #FF0000;
  /* Red color */
  cursor: pointer;
  transition: color 0.2s, transform 0.2s, box-shadow 0.2s;
  padding-left: 15px;
}

.delete-icon:hover {
  color: #a82222;
  /* Lighter red color on hover */
  cursor: pointer;
  transform: scale(1.1);
  /* Scale the icon on hover */
  /* You can also add a box-shadow or any other styles you prefer */
}

.clear-filter-button {
  position: absolute;
  right: 50px;
  top: 97px;
  width: 120px;
  border-radius: 5px;
  background-color: #dee2e6;
  font-size: 14px;
  height: 30px;
  border: 2px solid black;
  line-height: 0px;
}

.clear-filter-button:hover {
  background-color: #cecece;
  cursor: pointer;
}

.pagination-button-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
}

.pagination-info {
  font-size: 14px;
  color: #666;
  /* Adjust the color as needed */
  position: absolute;
  top: 250px;
  left: 50%;
  /* Center horizontally */
  transform: translateX(-50%);
  /* Center horizontally */
}

.wider-column {
  width: 42%;
}

.td-logbook {
  font-size: 13px;
}

.id-column {
  width: 5%;
}

.skinny-column {
  width: 7%;
}

.name-column {
  width: 9%;
}

.date-column {
  width: 9%;
}

.qr-code-icon {
  font-size: 20px;
  cursor: pointer;
  color: rgb(62, 62, 75);
}

.qr-code-icon:hover {
  color: #080707;
  cursor: pointer;
  transform: scale(1.1);
  transition: 0.2s;
}

.qr-code-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center !important;
  justify-content: center;
}

.qr-code-content {
  background: white;
  padding: 20px;
  border-radius: 8px;
  text-align: center;
  width: 500px;
}

.qr-code-img {
  margin: 20px 0;
}

.button-container {
  display: flex;
  justify-content: space-between;
  /* Aligns items to the right */
  align-items: center;
  margin-top: 20px;
  margin-bottom: 0px;
}

.ins-close-button {
  background-color: #6c757d;
  border: 1px solid #4d5358;
  color: white;
  padding: 5px 20px;
  border-radius: 5px;
  margin-left: 5%;
}

.ins-close-button:hover {
  background-color: #4d5358;
}

.ins-submit-button {
  background-color: brown;
  border: 1px solid rgb(92, 23, 23);
  color: white;
  cursor: pointer;
  border-radius: 5px;
  padding: 5px 20px;
  min-width: 180px;
  max-width: 230px;
  height: 35px;
  margin-right: 5%;
}

.ins-submit-button:hover {
  background-color: rgb(129, 33, 33);
}
</style>