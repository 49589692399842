<template>
  <div>
    <page-header></page-header>
    <div class="content">
      <div class="button-container">
        <button @click="generateQR" class="qr-button">Generate QR</button>
        <qr-code v-if="showQR" :text="qrText" ref="qrCodeCanvas" size="200"></qr-code>
        <button @click="multiSignOff" class="multiSignOffButton">Multi Sign Off</button>
      </div>
      <h1>Logbooks:</h1>
      <!-- Display the list of current products -->
      <div v-if="logbooks.length === 0" class="no-logbooks-message">
        <p style="color: rgb(216, 4, 4)">There are no logbooks assigned to you.</p>
      </div>
      <div v-else class="product-container">
        <div v-for="logbook in logbooks" :key="logbook.logbook_id"
          @click="openLogbook(logbook.logbook_id, logbook.product_id, logbook.trainee_id)" class="product-box">
          <div class="product-card">
            <h2 style="font-size: 18px;"><b>ID: {{ logbook.logbook_id }} </b> {{ logbook.name }} </h2>
            <span class="product-name">{{ logbook.productName }}</span>
            <div class="task-summary">
              <p>Total Tasks: {{ logbook.totalTasks }}</p>
              <p>Enabled Tasks: {{ logbook.enabledTasks }}</p>
              <p>Incomplete Tasks: {{ logbook.incompleteTasks }}</p>
            </div>
            <div class="emails">
              <p class="trainee-email">Trainee: {{ logbook.traineeName }}</p>
              <p class="trainee-email">Email: {{ logbook.traineeEmail }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PageHeader from './InstructorHeader.vue';
import axios from 'axios';
import VueQRCodeComponent from 'vue-qrcode-component';
import jsPDF from 'jspdf';

export default {
  name: 'InstructorHome',
  data() {
    return {
      logbooks: [],
      currentTime: '',
      loggedInUser: '',
      showModal: false,
      logbook: {
        totalTasks: 0,
        incompleteTasks: 0,
        enabledTasks: 0,
      },
      qrText: '',
      showQR: false,
      currentDate: new Date().toISOString(), // Save the current date in data for reuse
    };
  },
  components: {
    PageHeader,
    'qr-code': VueQRCodeComponent,
  },
  async mounted() {
    try {
      const instructorId = localStorage.getItem('user_id');
      localStorage.setItem(instructorId, 'instructor_id');
      const token = localStorage.getItem('token');
      const headers = { Authorization: `Bearer ${token}` };
      // Include the trainee_id parameter in the URL
      const result = await axios.get(`/api/instructor/${instructorId}/logbooks`, { headers });
      this.logbooks = result.data.logbooks;
      // Loop through logbooks and fetch tasks for each
      const productResponse = await axios.get(`/api/products`, { headers });
      const traineeResponse = await axios.get(`/api/users`, { headers });
      const tasksResult = await axios.get(`/api/tasks`, { headers });
      const logbookEntriesResult = await axios.get(`/api/logbookentry`, { headers });

      // Map product_id to product_name in logbooks
      this.logbooks.forEach(logbook => {
        const product = productResponse.data.find(product => product.product_id === logbook.product_id);
        const trainee = traineeResponse.data.find(trainee => trainee.user_id === logbook.trainee_id);
        const tasks = tasksResult.data.filter(task => task.prod_id === logbook.product_id);
        const entries = logbookEntriesResult.data.filter(entries => entries.logbook_id === logbook.logbook_id);
        if (entries && entries.length > 0) {
          logbook.incompleteTasks = entries.filter(entries => entries.completed == null).length;
          logbook.enabledTasks = entries.length;
        }
        if (entries.length === 0) {
          logbook.incompleteTasks = 0;
          logbook.enabledTasks = 0;
        }
        if (tasks && tasks.length > 0) {
          logbook.tasks = tasks;
          logbook.totalTasks = tasks.length;
        }
        if (trainee) {
          logbook.traineeName = trainee.first_name + ' ' + trainee.last_name;
          logbook.traineeEmail = trainee.email;
        }
        if (product) {
          logbook.productName = product.name;
        }
      });

      // for (const logbook of this.logbooks) {
      //   const productId = logbook.product_id;
      //   const productName = await this.fetchproductName(productId, headers);
      //   logbook.productName = productName;
      //   localStorage.setItem('productName', logbook.productName);
      //   const traineeId = logbook.trainee_id;
      //   localStorage.setItem('trainee_id', traineeId);
      //   const traineeEmail = await this.fetchTraineeEmail(traineeId, headers);
      //   logbook.traineeEmail = traineeEmail;
      //   logbook.traineeName = localStorage.getItem('traineeName');
      //   const logbookId = logbook.logbook_id;
      //   const tasksResult = await axios.get(`/api/${logbookId}/tasks`, { headers });
      //   logbook.tasks = tasksResult.data.tasks;
      //   const logbookEntriesResult = await axios.get(`/api/logbookentry/${logbook.logbook_id}`, { headers });
      //   logbook.totalTasks = tasksResult.data.tasks.length;
      //   logbook.incompleteTasks = logbookEntriesResult.data.tasks.filter(entry => entry.completed == null).length;
      //   logbook.enabledTasks = logbookEntriesResult.data.tasks.length;
      // }
      const loggedInUser = localStorage.getItem('username');
      this.loggedInUser = loggedInUser;
    } catch (error) {
      if (error.response && error.response.status === 401) {
        // Unauthorized, redirect to login page
        this.$router.push({ name: 'LoginPage' });
      }
    }
  },
  methods: {
    async openLogbook(logbookId, productId, traineeId) {
      const token = localStorage.getItem('token');
      const headers = { Authorization: `Bearer ${token}` };
      localStorage.setItem('logbook_id', logbookId);
      localStorage.setItem('productId', productId);
      localStorage.setItem('trainee_id', traineeId);
      const response = await axios.get(`/api/users/${traineeId}`, { headers });
      const trainee_name = response.data.first_name + ' ' + response.data.last_name;
      localStorage.setItem('traineeName', trainee_name);
      if (!token) {
        this.$router.push({ name: 'examinerLogin' });
      }
      this.$router.push({ name: 'InstructorLogbook', params: { logbookId, productId, traineeId } });
    },
    async fetchproductName(productId, headers) {
      try {
        const response = await axios.get(`/api/products/${productId}`, { headers });
        return response.data.name;
      } catch (error) {
        // Handle error if the product name cannot be fetched
        console.error(`Error fetching product name for product ${productId}: ${error.message}`);
        return ''; // Return an empty string as a fallback
      }
    },
    async multiSignOff() {
      this.$router.push({ name: 'MultiSignOff' })
    },
    async generateQR() {
      this.$router.push({ name: 'GenerateQR' })
    },
    generateAndDownloadQRCode() {
      // Generate the text for the QR code including the timestamp
      const timestamp = new Date().toISOString();
      this.qrText = `SE Signed Off at ${timestamp}`;
      this.showQR = true;

      this.$nextTick(() => {
        const qrCanvas = this.$refs.qrCodeCanvas.$el.querySelector('canvas');
        if (qrCanvas) {
          const options = {
            year: 'numeric', month: 'long', day: 'numeric',
            hour: '2-digit', minute: '2-digit', second: '2-digit'
          };
          const formattedDateTime = new Date().toLocaleDateString('en-US', options);

          // Create a new jsPDF instance with A4 paper size
          const pdf = new jsPDF({
            orientation: 'portrait',
            unit: 'mm',
            format: 'a4'
          });

          // Calculate the width and height scaling factors
          // Convert the canvas width from pixels to mm (210mm is the width of A4 paper)
          const scaleFactor = 210 / (qrCanvas.width / (25.4 / 96));

          // Calculate the dimensions for the QR code on the PDF
          const qrWidth = qrCanvas.width * scaleFactor;
          const qrHeight = qrCanvas.height * scaleFactor;

          // Convert canvas to image data
          const imgData = qrCanvas.toDataURL('image/png');

          // The x and y coordinates where the image will be placed on the PDF
          const x = (pdf.internal.pageSize.getWidth() - qrWidth) / 2;
          const y = (pdf.internal.pageSize.getHeight() - qrHeight) / 2;

          // Add the text to the PDF at the calculated position
          pdf.text(`QR code for SE. \n Date - ${formattedDateTime}`, 105, y - 10, { align: 'center' });

          // Then add the QR code image
          pdf.addImage(imgData, 'PNG', x, y, qrWidth, qrHeight);

          // Save the PDF
          pdf.save('SE-QRCode.pdf');

          // Hide the QR code element again if desired
          this.showQR = false;
        }
      });
    },
    formattedDateTime() {
      const options = { year: 'numeric', month: 'long', day: 'numeric', hour: '2-digit', minute: '2-digit' };
      return new Date(this.currentDate).toLocaleDateString('en-US', options) +
        ' ' +
        new Date(this.currentDate).toLocaleTimeString('en-US', options);
    },
  }
}
</script>
<style scoped>
button {
  margin-top: 20px;
}
.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 10px;
  /* Add space from the top */
}

.product-name {
  font-size: 1rem;
  /* Set the font size for the product name */
  color: grey;
  /* Set the text color to grey */
  margin-top: 40px;
  /* Add some space between the logbook name and product name */
  position: absolute;
  margin-left: 1%;
  border: none;
}

.trainee-email,
.instructor-email {
  font-size: 0.9rem;
  /* Set the font size for the emails */
  color: grey;
  /* Set the text color to grey */
  margin: 0;
  margin-top: 8px;
  /* Reset margin to remove any default spacing */
}

.product-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  margin-top: 0;
  width: 90%;
}

h2 {
  font-size: 1.3rem;
  margin-bottom: 25px;
}

h1 {
  font-size: 2rem;
  margin-top: 0;
  margin-bottom: 20px;
  padding-top: 0px;
  top: 7px;
}

.product-box {
  margin: 10px;
  width: 100%;
  height: 80px;
}

.viewbutton-container {
  display: flex;
  justify-content: flex-start;
  /* Align the button to the left within the container */
  width: 100%;
  /* Make the container take up the full width */
  margin-top: 0;
}

.product-card {
  background-color: #fff;
  padding: 10px;
  border-radius: 8px;
  box-shadow: 3px 3px 3px rgba(0, 0, 0, 0.2),
    /* Right and bottom */
    -3px -3px 3px rgba(0, 0, 0, 0.2),
    /* Left and top */
    0 0 3px rgba(0, 0, 0, 0.2);
  /* Inner shadow */
  transition: transform 0.2s ease-in-out;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 95px;
  margin-bottom: 0;
  /* Center content horizontally within the card */
}

.product-card h2 {
  margin-left: 0;
}

.product-card:hover {
  transform: scale(1.03);
}

.view-button {
  /* Rounded corners for the button */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  /* Shadow effect */
  background-color: #0a407a;
  /* Button background color */
  color: #ffffff;
  /* Button text color */
  padding: 10px 15px;
  width: 20%;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin-bottom: auto;
  margin-bottom: 1px;
  /* Add spacing between the text and the button */
}

.task-summary {
  margin-top: 15px;
  text-align: center;
}

.task-summary p {
  margin-bottom: 3px;
  /* Adjust as needed to reduce the gap */
}

.multiSignOffButton:hover {
  background-color: #012c5e;
}

.button-container {
  display: flex;
  justify-content: flex-end;
  /* Align horizontally to the right */
  align-items: center;
  /* Align vertically in the center */
  padding-right: 20px;
  /* Spacing from the right edge of the page */
  position: absolute;
  /* Fixed positioning relative to the viewport */
  right: 0;
  /* Align to the far right */
  top: 50px;
  /* Positioned down from the top of the viewport */
  z-index: 1000;
  /* Ensure it's above other content */
}

.qr-button,
.multiSignOffButton {
  border-radius: 5px;
  min-width: 150px;
  /* Minimum width for buttons */
  padding: 10px 20px;
  /* Padding inside the buttons */
  background-color: #003f86;
  color: white;
  margin-left: 10px;
  /* Spacing between buttons */
  transition: all 0.3s;
  /* Smooth transition for size changes */
}

/* Style adjustments for smaller screens */
@media (max-width: 768px) {
  .button-container {
    justify-content: space-around;
    /* Distribute space around items for smaller screens */
  }

  .qr-button,
  .multiSignOffButton {
    /*padding: 8px 10px; /* Smaller padding for smaller buttons */
    font-size: 14px;
    /* Smaller font size for smaller screens */
    min-width: 120px;
    /* Smaller minimum width */
    margin-left: 5px;
    /* Smaller margin between buttons */
  }
}

/* Further adjustments for even smaller screens */
@media (max-width: 480px) {
  .button-container {
    flex-direction: column;
    /* Stack buttons vertically on very small screens */
    top: 10px;
    /* Adjust top positioning if necessary */
  }

  .qr-button,
  .multiSignOffButton {
    width: auto;
    /* Full width of the container */
    margin: 5px 0;
    /* Adjust margin for stacked layout */
  }
}
</style>