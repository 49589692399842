<template>
    <div class="Task-modal-overlay" @click="close">
        <div class="Task-modal-content">
                <div class="Task-modal-header">
        <div class="Task-modal-dialog" @click.stop>
                    <h5 class="Task-modal-title">Task ID: {{ taskId }}</h5>
                </div>   
            </div>  
        <table class="data-table">
        <tbody>
          <tr>
            <td class="fieldset-column"><strong>Task ID:</strong></td>
            <td>{{ task.task_id  }}</td>
          </tr>
          <tr>
            <td class="fieldset-column"><strong>Task No:</strong></td>
            <td>{{ task.task_no  }}</td>
          </tr>
          <tr>
            <td><strong>Chapter No:</strong></td>
            <td>{{ task.chapt_no }}</td>
          </tr>
          <tr>
            <td><strong>Module:</strong></td>
            <td>{{ task.module }}</td>
          </tr>
          <tr>
            <td><strong>Location:</strong></td>
            <td>{{ task.location }}</td>
          </tr>
          <tr>
            <td><strong>Area:</strong></td>
            <td>{{ task.area }}</td>
          </tr>
          <tr>
            <td><strong>AMTOSS:</strong></td>
            <td>{{ task.AMTOSS }}</td>
          </tr>
          <tr>
            <td><strong>Mandatory / Obligatory:</strong></td>
            <td> {{ task.manob }} </td>
          </tr>
          <tr>
            <td><strong>Activity:</strong></td>
            <td>{{ task.activity }}</td>
          </tr>
          <tr>
            <td><strong>Examinable:</strong></td>
        <td>{{ task.is_exam ? 'True' : 'False' }}</td>
          </tr>
          <tr>
            <td><strong>Product ID:</strong></td>
            <td>{{ task.prod_id }}</td>
          </tr>
          <tr>
            <td><strong>Category:</strong></td>
            <td>{{ task.category }}</td>
          </tr>
          <tr>
            <td><strong>Level:</strong></td>
            <td>{{ task.level }}</td>
          </tr>
          <tr>
            <td><strong>Tail No:</strong></td>
            <td>{{ task.ac_reg }}</td>
          </tr>
          <tr>
            <td><strong>Jobcard No:</strong></td>
            <td>{{ task.jobcard }}</td>
          </tr>
          <tr>
            <td><strong>AMM Reference:</strong></td>
            <td>{{ task.amm_ref }}</td>
          </tr>
          <tr>
            <td><strong>Proof Data:</strong></td>
            <td>{{ task.proof_data }}</td>
          </tr>
          <tr>
            <td><strong>Enabled:</strong></td>
            <td>{{ task.enabled }}</td>
          </tr>
          <tr>
            <td><strong>Completed:</strong></td>
            <td>{{ task.completed }}</td>
          </tr>
          <tr>
            <td><strong>Ins Sign Off:</strong></td>
            <td>{{ task.ins_so }}</td>
          </tr>
        </tbody>
      </table>
    </div>
        </div>
</template>
  
  <script>
  export default {
    props: {
      task: {
            type: Object,
            required: true
        },
        logbookId: {
            type: [String, Number],
            required: true
        },
    },
    data() {
        return {
            showInsTaskLongFormModal: true,
            taskData: [],
        };
    },
    methods: {
        close() {
            this.$emit('close');
        }, 
    },
  }
  </script>
  
  <style scoped>
  .Task-modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    background-color: rgba(146, 146, 146, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
  }
  .Task-modal-content {
    margin-top: 40px;
    background-color: rgb(239, 244, 255);
    padding: 10px;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);
    max-width: 50%; /* You can adjust the maximum width as needed */
    max-height: 87vh; /* You can adjust the maximum height as needed */
    overflow-y: auto;
    border: 2px solid rgb(83, 83, 83);
    margin-top: 80px;
  }
  .Task-modal-title {
    margin-bottom: 10px;
    float: left;
    margin-left: 20px;
    color: black;
  }
  .data-table {
    border-collapse: collapse;
    background-color: rgb(253, 253, 255);
    border: 1px solid grey;
    box-shadow: 0;
  }
  .data-table td, .data-table th {
    border: none;
    padding: 8px;
    width: 250px;
  }
  </style>